import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import './styles/global.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import Header from "./components/Header/Header";
import './assets/fonts/GROBOLD.ttf';
import Footer from "./components/Footer/Footer";
// import { WagmiConfig, createConfig, configureChains } from 'wagmi'
// import { polygonMumbai } from "wagmi/chains"
//
// import { alchemyProvider } from 'wagmi/providers/alchemy'
//
// import { InjectedConnector } from 'wagmi/connectors/injected'
// import { MetaMaskConnector } from 'wagmi/connectors/metaMask'
//
// const { chains, publicClient, webSocketPublicClient } = configureChains(
//   [polygonMumbai],
//   [alchemyProvider({ apiKey: process.env.ALCHEMY_API_KEY })],
// )
//
// const config = createConfig({
//   autoConnect: true,
//   connectors: [
//     new MetaMaskConnector({ chains }),
//     new InjectedConnector({
//       chains,
//       options: {
//         name: 'Injected',
//         shimDisconnect: true,
//       },
//     }),
//   ],
//   publicClient,
//   webSocketPublicClient,
// })

ReactDOM.render(
  <React.StrictMode>
    {/*<WagmiConfig config={config}>*/}
      <BrowserRouter>
        <Header/>
        <App/>
        <Footer/>
      </BrowserRouter>
    {/*</WagmiConfig>*/}
  </React.StrictMode>,
  document.getElementById('root'),
);

reportWebVitals();
