import React, { useEffect, useState } from 'react';
import boxBg from "../../assets/image/hero-item-box.svg"
import eyes from "../../assets/image/hero-section-eyes.svg"
import facial_hair from "../../assets/image/hero-section-facial-hair.svg"
import background_color_section from "../../assets/image/background-color.svg"
import eyebrows from "../../assets/image/hero-section-eyebrows.svg"
import hair from "../../assets/image/hero-section-hair.svg"
import closes from "../../assets/image/hero-section-closes.svg"
import extra from "../../assets/image/hero-section-extra.svg"
import classes from "./styles/create-hero.module.css";
import clsx from "clsx";
import heroSectionBg from "../../assets/image/hero-section-bg-circle.svg"
import CartoonButton from "../../components/CartoonButton/CartoonButton";
import HBA from "../../artifacts/contracts/HeroesBattleArenaAvatar.sol/HeroesBattleArenaAvatar.json"
import beard_1 from "../../assets/icons/character-builder/Beard/crossbowman_beard.png"
import beard_2 from "../../assets/icons/character-builder/Beard/dwarf_beard.png"
import beard_3 from "../../assets/icons/character-builder/Beard/knight_beard.png"
import beard_4 from "../../assets/icons/character-builder/Beard/mage_beard.png"
import beard_5 from "../../assets/icons/character-builder/Beard/warrior_beard.png"
import beard_6 from "../../assets/icons/character-builder/Beard/warrior2_beard.png"
import beard_7 from "../../assets/icons/character-builder/Beard/warrior3_beard.png"
import beard_8 from "../../assets/icons/character-builder/Beard/warrior4_beard.png"
import brows_1 from "../../assets/icons/character-builder/Brows/archer_brows.png"
import brows_2 from "../../assets/icons/character-builder/Brows/barbarian_brows.png"
import brows_3 from "../../assets/icons/character-builder/Brows/crossbowman_brows.png"
import brows_4 from "../../assets/icons/character-builder/Brows/druid_brows.png"
import brows_5 from "../../assets/icons/character-builder/Brows/dwarf_brows.png"
import brows_6 from "../../assets/icons/character-builder/Brows/elf_brows.png"
import brows_7 from "../../assets/icons/character-builder/Brows/kamikaze_brows.png"
import brows_8 from "../../assets/icons/character-builder/Brows/knight_brows.png"
import brows_9 from "../../assets/icons/character-builder/Brows/mage_brows.png"
import brows_10 from "../../assets/icons/character-builder/Brows/warrior_brows.png"
import brows_11 from "../../assets/icons/character-builder/Brows/warrior2_brows.png"
import brows_12 from "../../assets/icons/character-builder/Brows/warrior3_brows.png"
import brows_13 from "../../assets/icons/character-builder/Brows/warrior4_brows.png"
import brows_14 from "../../assets/icons/character-builder/Brows/warrior5_brows.png"
import brows_15 from "../../assets/icons/character-builder/Brows/warrior6_brows.png"
import clothes_1 from "../../assets/icons/character-builder/Clothes/druid_clothes.png"
import clothes_2 from "../../assets/icons/character-builder/Clothes/elf_clothes.png"
import clothes_3 from "../../assets/icons/character-builder/Clothes/medic_clothes.png"
import clothes_4 from "../../assets/icons/character-builder/Clothes/warrior4_clothes.png"
import clothes_5 from "../../assets/icons/character-builder/Clothes/mask1.png"
import clothes_6 from "../../assets/icons/character-builder/Clothes/mask2.png"
import clothes_7 from "../../assets/icons/character-builder/Clothes/clothes1.png"
import clothes_8 from "../../assets/icons/character-builder/Clothes/clothes2.png"
import clothes_9 from "../../assets/icons/character-builder/Clothes/clothes3.png"
import clothes_10 from "../../assets/icons/character-builder/Clothes/clothes4.png"
import clothes_11 from "../../assets/icons/character-builder/Clothes/clothes5.png"
import clothes_12 from "../../assets/icons/character-builder/Clothes/clothes6.png"
import clothes_13 from "../../assets/icons/character-builder/Clothes/clothes7.png"
import clothes_14 from "../../assets/icons/character-builder/Clothes/clothes8.png"
import clothes_15 from "../../assets/icons/character-builder/Clothes/clothes9.png"
import clothes_16 from "../../assets/icons/character-builder/Clothes/clothes10.png"
import clothes_17 from "../../assets/icons/character-builder/Clothes/clothes11.png"
import clothes_18 from "../../assets/icons/character-builder/Clothes/clothes12.png"
import eyes_1 from "../../assets/icons/character-builder/Eyes/barbarian_eyes.png"
import eyes_3 from "../../assets/icons/character-builder/Eyes/druid_eyes.png"
import eyes_4 from "../../assets/icons/character-builder/Eyes/dwarf_eyes.png"
import eyes_5 from "../../assets/icons/character-builder/Eyes/elf_eyes.png"
import eyes_6 from "../../assets/icons/character-builder/Eyes/kamikaze_eyes.png"
import eyes_8 from "../../assets/icons/character-builder/Eyes/mage_eyes.png"
import eyes_9 from "../../assets/icons/character-builder/Eyes/medic_eyes.png"
import eyes_10 from "../../assets/icons/character-builder/Eyes/paladin_eyes.png"
import eyes_13 from "../../assets/icons/character-builder/Eyes/warrior4_eyes.png"
import eyes_14 from "../../assets/icons/character-builder/Eyes/warrior5_eyes.png"
import eyes_15 from "../../assets/icons/character-builder/Eyes/warrior6_eyes.png"
import eyes_16 from "../../assets/icons/character-builder/Eyes/warrior_eyes.png"
import hair_1 from "../../assets/icons/character-builder/Hair/archer_hair.png"
import hair_2 from "../../assets/icons/character-builder/Hair/barbarian_hair.png"
import hair_3 from "../../assets/icons/character-builder/Hair/druid_hair.png"
import hair_4 from "../../assets/icons/character-builder/Hair/elf_hair.png"
import hair_5 from "../../assets/icons/character-builder/Hair/kamikaze_hair.png"
import hair_6 from "../../assets/icons/character-builder/Hair/medic_hair.png"
import hair_7 from "../../assets/icons/character-builder/Hair/warrior3_hair.png"
import hair_8 from "../../assets/icons/character-builder/Hair/warrior4_hair.png"
import hair_9 from "../../assets/icons/character-builder/Hair/warrior5_hair.png"
import hair_10 from "../../assets/icons/character-builder/Hair/warrior6_hair.png"
import hair_11 from "../../assets/icons/character-builder/Hair/warrior_hair.png"
import weapon_1 from "../../assets/icons/character-builder/Weapon/archer_weapon.png"
import weapon_2 from "../../assets/icons/character-builder/Weapon/archer_weapon2.png"
import weapon_3 from "../../assets/icons/character-builder/Weapon/barbarian_weapon.png"
import weapon_4 from "../../assets/icons/character-builder/Weapon/crossbowman_weappon.png"
import weapon_5 from "../../assets/icons/character-builder/Weapon/druid_weapon.png"
import weapon_6 from "../../assets/icons/character-builder/Weapon/dwarf_weapon.png"
import weapon_7 from "../../assets/icons/character-builder/Weapon/knight_weapon.png"
import weapon_8 from "../../assets/icons/character-builder/Weapon/mage_weapon.png"
import weapon_9 from "../../assets/icons/character-builder/Weapon/paladin_weapon.png"
import weapon_11 from "../../assets/icons/character-builder/Weapon/warrior3_weapon.png"
import weapon_12 from "../../assets/icons/character-builder/Weapon/warrior_weapon.png"
import body_layer from "../../assets/icons/character-builder/body_layer.png"
import html2canvas from "html2canvas";
import detectEthereumProvider from '@metamask/detect-provider'
import { ethers } from "ethers";
import axios from "axios";

const bodyParts = [
  {
    icon: eyes,
    text: "Eyes"
  },
  {
    icon: facial_hair,
    text: "Facial Hair"
  },
  {
    icon: eyebrows,
    text: "Eyebrows"
  },
  {
    icon: hair,
    text: "Hair"
  },
  {
    icon: closes,
    text: "Clothes"
  },
  {
    icon: extra,
    text: "Extra"
  },
  {
    icon: background_color_section,
    text: "Back"
  }
]

const levels = [
  "Level 1",
  "Level 2",
  "Level 3",
]

const items = {
  "Eyes": [
    undefined, eyes_1, eyes_3, eyes_4, eyes_5, eyes_6, eyes_8, eyes_9, eyes_10, eyes_13, eyes_14, eyes_15, eyes_16
  ],
  "Facial Hair": [
    undefined, beard_1, beard_2, beard_3, beard_4, beard_5, beard_6, beard_7, beard_8
  ],
  "Eyebrows": [
    undefined, brows_1, brows_2, brows_3, brows_4, brows_5, brows_6, brows_7, brows_8, brows_9, brows_10, brows_11, brows_12, brows_13, brows_14, brows_15
  ],
  "Hair": [
    undefined, hair_1, hair_2, hair_3, hair_4, hair_5, hair_6, hair_7, hair_8, hair_9, hair_10, hair_11
  ],
  "Clothes": [
    undefined, clothes_1, clothes_2, clothes_3, clothes_4, clothes_5, clothes_6, clothes_7, clothes_8, clothes_9, clothes_10, clothes_11, clothes_12, clothes_13, clothes_14, clothes_15, clothes_16, clothes_17, clothes_18
  ],
  "Extra": [
    undefined, weapon_1, weapon_2, weapon_3, weapon_4, weapon_5, weapon_6, weapon_7, weapon_8, weapon_9, weapon_11, weapon_12
  ],
  "Back": [
    undefined,
    "#f44336",
    "#e91e63",
    "#9c27b0",
    "#673ab7",
    "#3f51b5",
    "#2196f3",
    "#03a9f4",
    "#00bcd4",
    "#009688",
    "#4caf50",
    "#8bc34a",
    "#cddc39",
    "#ffeb3b",
    "#ffc107",
    "#ff9800",
    "#ff5722",
    "#795548",
    "#607d8b"
  ]
}

const initOptions = {
  "Eyes": 0,
  "Facial Hair": 0,
  "Eyebrows": 0,
  "Hair": 0,
  "Clothes": 0,
  "Extra": 0,
  "Back": 0
}
const initialState = { accounts: [] }

const contractAddress = process.env.REACT_APP_CONTRACT_ADDRESS
const provider = ((window.ethereum != null) ? new ethers.providers.Web3Provider(window.ethereum) : ethers.providers.getDefaultProvider());

const signer = provider.getSigner ? provider.getSigner() : null;

const contract = signer ? new ethers.Contract(contractAddress, HBA.abi, signer) : null;

const CreateHero = () => {
  const [activeBodyPart, setActiveBodyPart] = useState(bodyParts[0].text);
  const [activeLevel, setActiveLevel] = useState(initOptions);
  const [activeItem, setActiveItem] = useState(initOptions);
  // eslint-disable-next-line
  const [hasProvider, setHasProvider] = useState(null)
  const [wallet, setWallet] = useState(initialState)

  useEffect(() => {
    const refreshAccounts = (accounts) => {
      if (accounts.length > 0) {
        updateWallet(accounts)
      } else {
        setWallet(initialState)
      }
    }

    const getProvider = async () => {
      const provider = await detectEthereumProvider({ silent: true })
      setHasProvider(Boolean(provider))

      if (provider) {
        const accounts = await window.ethereum.request(
          { method: 'eth_accounts' }
        )
        refreshAccounts(accounts)
        window.ethereum.on('accountsChanged', refreshAccounts)
      }
    }

    console.log(window.ethereum)
    getProvider()
    return () => {
      // window.ethereum?.removeListener('accountsChanged', refreshAccounts)
    }
  }, [])

  const updateWallet = async (accounts) => {
    setWallet({ accounts })
  }

  const handleConnect = async () => {
    let accounts = await window.ethereum.request({
      method: "eth_requestAccounts",
    })
    await updateWallet(accounts)
  }

  const selectItem = (itemIdx) => {
    setActiveItem(state => ({
      ...state,
      [activeBodyPart]: itemIdx
    }))
  }

  const selectLevel = (levelIdx) => {
    setActiveLevel(state => ({
      ...state,
      [activeBodyPart]: levelIdx
    }))
  }

  const getLevel = (key, itemIdx) => {
    console.log(items, key, itemIdx)
    const level1 = items[key].length / 3
    const level2 = level1 * 2
    return itemIdx < level1 ? 1 : itemIdx < level2 ? 2 : 3
  }

  const getMintedStatus = async (metadataURI) => {
    const result = await contract.isContentOwned(metadataURI);
    console.log(result)
  }

  const getPriceByLevel = (level) => {
    return level === 1 ? ethers.utils.parseEther("2") : level === 2 ? ethers.utils.parseEther("3") : ethers.utils.parseEther("4")
  }

  const createAvatar = async () => {
    const background = items.Back[activeItem.Back]
    let combinedSvg = `
      <div
        id="avatar"
        style="position:relative;width:452px;height:452px;"
      >`;
    const addIfAvailable = (element, typeKey) => {
      if (element !== undefined && element !== null) {
        const newElement = element.cloneNode(true);

        newElement.style.position = "absolute";
        newElement.style.top = "0";
        newElement.style.left = "0";
        newElement.style.width = "100%";
        newElement.style.height = "100%";

        combinedSvg = combinedSvg + newElement.outerHTML;
        combinedSvg = combinedSvg + "</img>";
      }
    };
    const avatarDiv = document.querySelector("#avatar");

    addIfAvailable(avatarDiv.querySelector("#base"));

    Object.entries(items).forEach(([type, elements]) => {
      if (type === "Back") return;
      const typeKey = type.toLowerCase().replaceAll(" ", "_")
      addIfAvailable(avatarDiv.querySelector(`#${typeKey}`), typeKey);
    })

    combinedSvg = combinedSvg + "</div>";

    const canvas = document.createElement("canvas");
    canvas.width = 452;
    canvas.height = 452;

    var iframe = document.createElement('iframe');

    document.body.appendChild(iframe);
    setTimeout(async () => {
      var iframedoc = iframe.contentDocument || iframe.contentWindow.document;
      iframedoc.body.innerHTML = combinedSvg;
      const canvas = await html2canvas(iframedoc.body, { backgroundColor: background });
      document.body.removeChild(iframe);
      const image = canvas.toDataURL("image/png");
      var formData = new FormData();
      formData.append("image", image);
      await contract.connect(signer);
      let maxLevel = 1;
      const attributes = Object.entries(activeItem).map(([key, value]) => {
        const level = getLevel(key, value)
        maxLevel = Math.max(maxLevel, level)
        return {
          trait_type: key + " Level",
          value: level
        }
      })
      const heroNumber = Number(await contract.count()) + 1;
      console.log("heroNumber", heroNumber)
      const metadata = {
        name: `HBA Hero ${heroNumber}`,
        description: "HBA Hero Description",
        attributes,
      }
      try {
        const recipient = wallet.accounts[0]
        const res = await axios.post(
          "/.netlify/functions/upload",
          { image, metadata, wallet: recipient }
        )
        console.log(res)
        const ipfsHash = res.data.uri
        const metadataURI = ipfsHash
        const result = await contract.payToMint(
          recipient,
          metadataURI,
          { value: getPriceByLevel(maxLevel) }
        )
        console.log("result", result)
        await result.wait();
        console.log(result)
        await getMintedStatus(metadataURI)
      } catch (e) {
        console.log(e)
      }
      // await axios.get("/upload", { data: JSON.stringify({ image }) })
      // JSON.stringify(image)
      // console.log(image)

      // const a = document.createElement('a')
      // a.download = 'avatar.png'
      // a.href = image
      // a.click()
    }, 10);

  }

  const itemsToRender = (
    activeBodyPart === "Back"
      ? items[activeBodyPart]
      : items[activeBodyPart]
        .slice(
          0,
          Math.max(2, items[activeBodyPart].length / (3 - activeLevel[activeBodyPart]))
        )
  )
  const heroBackground = activeItem.Back ? items.Back[activeItem.Back] : "white"
  console.log(wallet)
  return (
    <div id={"create-hero"} className={classes.wrapper}>
      <div className={classes.headline}>
        <h2>Create your own NFT hero, ready to battle and start the battle!</h2>
        <p>In Heroes Battle Arena, the power is in your hands! Create your own hero, customizing every aspect of their
          appearance and abilities to your liking. <br/><br/>
          So why not create your hero and start your journey today? <b>The arena is
            calling!</b> Only <b>10,000</b> Avatars are available</p>
      </div>
      <div className={classes.characterView}>
        <div className={classes.gearSelection}>
          <div className={classes.bodyPartSelection}>
            {bodyParts.map((i, idx) => (
              <div
                className={clsx(classes.bodyPart, activeBodyPart === i.text && classes.activeBodyPart)}
                onClick={() => setActiveBodyPart(i.text)}
                key={idx}
              >
                <img src={heroSectionBg} className={classes.bg} alt=""/>
                <img src={i.icon} alt=""/>
                <span>{i.text}</span>
              </div>
            ))}
          </div>
          <div className={classes.bodyItemsWrapper}>
            <div className={classes.levelSelection}>
              {levels.map((i, idx) => (
                <CartoonButton
                  onClick={() => selectLevel(idx)}
                  size={"big"}
                  disabled={activeBodyPart === "Back"}
                  className={clsx(classes.level, activeLevel[activeBodyPart] === idx && classes.activeLevel)}
                  key={idx}
                >
                  {i}
                </CartoonButton>
              ))}
            </div>
            <div className={classes.itemSelection}>
              {itemsToRender
                .map((i, idx) => (
                  <div
                    onClick={() => selectItem(idx)}
                    className={clsx(classes.item, activeItem[activeBodyPart] === idx && classes.activeItem)}
                    key={idx}
                  >
                    <img src={boxBg} className={classes.bg} alt=""/>
                    {i && (activeBodyPart === "Back"
                        ? <div className={classes.color} style={{ backgroundColor: i }}/>
                        : <img src={i} className={classes.itemIcon} alt=""/>
                    )}
                  </div>
                ))}
            </div>
          </div>
          <CartoonButton className={classes.mobileButton}>
            MINT NOW
          </CartoonButton>
        </div>
        <div className={classes.previewCharacter}>

          <div id={"avatar"} className={classes.character}>
            {Object.entries(items).map(([type, elements], idx) => {
              const typeKey = type.toLowerCase().replaceAll(" ", "_")
              const file = elements[activeItem[type]]
              return file && (
                <img
                  src={file}
                  className={classes[typeKey]}
                  id={typeKey}
                  alt=""
                  key={idx}
                />
              )
            })}
            <img
              src={body_layer}
              id="base"
              className={classes.hero}
              alt=""
            />
            <div
              id={"back"}
              className={classes.color}
              style={{ backgroundColor: heroBackground }}
            />
          </div>
          {contract ?
            wallet.accounts.length > 0 ?
              <CartoonButton onClick={createAvatar}>
                MINT NOW
              </CartoonButton>
              : (window.ethereum && window.ethereum.isMetaMask && wallet.accounts.length < 1) ? (
                <CartoonButton onClick={handleConnect}>
                  CONNECT
                </CartoonButton>
              ) : null
            : <CartoonButton onClick={() => {
            }}>
              WEB3 WALLET REQUIRED
            </CartoonButton>}
        </div>
      </div>
    </div>
  );
};

export default CreateHero;
