import React from 'react';
import classes from "./styles/our-support.module.css";
import support_1 from "../../assets/image/support/1.png"
import support_3 from "../../assets/image/support/3.png"
import support_4 from "../../assets/image/support/4.png"
import support_5 from "../../assets/image/support/5.png"
import support_6 from "../../assets/image/support/6.png"
import support_7 from "../../assets/image/support/7.png"
import support_8 from "../../assets/image/support/8.png"
import support_9 from "../../assets/image/support/9.png"
import support_10 from "../../assets/image/support/10.png"
import support_11 from "../../assets/image/support/11.png"
import support_12 from "../../assets/image/support/12.png"
import support_13 from "../../assets/image/support/13.png"
import support_14 from "../../assets/image/support/14.png"
import support_15 from "../../assets/image/support/15.png"
import support_16 from "../../assets/image/support/16.png"
import support_17 from "../../assets/image/support/17.png"
import support_18 from "../../assets/image/support/18.png"
import support_19 from "../../assets/image/support/19.png"
import support_20 from "../../assets/image/support/20.png"
import support_21 from "../../assets/image/support/21.png"
import support_22 from "../../assets/image/support/22.png"
import support_23 from "../../assets/image/support/23.png"
import support_24 from "../../assets/image/support/24.png"
import support_25 from "../../assets/image/support/25.png"
import support_26 from "../../assets/image/support/26.png"
import support_27 from "../../assets/image/support/27.png"
import support_28 from "../../assets/image/support/28.png"
import support_29 from "../../assets/image/support/29.png"
import support_30 from "../../assets/image/support/30.png"
import support_31 from "../../assets/image/support/31.png"
import support_32 from "../../assets/image/support/32.png"
import support_33 from "../../assets/image/support/33.png"
import support_34 from "../../assets/image/support/34.png"
import support_35 from "../../assets/image/support/35.png"
import support_36 from "../../assets/image/support/36.png"
import support_37 from "../../assets/image/support/37.png"
import support_38 from "../../assets/image/support/38.png"
import support_39 from "../../assets/image/support/39.png"
import support_40 from "../../assets/image/support/40.png"
import support_41 from "../../assets/image/support/41.png"
import support_43 from "../../assets/image/support/43.png"
import support_44 from "../../assets/image/support/44.png"
import support_45 from "../../assets/image/support/45.png"
import support_46 from "../../assets/image/support/46.png"
import support_47 from "../../assets/image/support/47.png"
import support_48 from "../../assets/image/support/48.png"
import support_50 from "../../assets/image/support/50.png"
import support_1_add from "../../assets/image/support/1_add.png"
import support_2_add from "../../assets/image/support/2_add.png"
import support_3_add from "../../assets/image/support/3_add.png"
import support_4_add from "../../assets/image/support/4_add.png"
import support_5_add from "../../assets/image/support/5_add.png"
import support_6_add from "../../assets/image/support/6_add.png"
import support_7_add from "../../assets/image/support/7_add.png"
import support_9_add from "../../assets/image/support/9_add.png"
import support_10_add from "../../assets/image/support/10_add.png"
import topImg from "../../assets/image/our-support-top.svg"
import topImgMobile from "../../assets/image/our-support-top-mobile.svg"
import bottomImg from "../../assets/image/our-support-bottom.svg"
import bottomImgMobile from "../../assets/image/our-support-bottom-mobile.svg"

const supportImages = [
  { logo: support_1, url: "https://alphaguilty.io/ru" },
  { logo: support_3, url: "https://bitgert.com/" },
  { logo: support_4, url: "https://galacticreborn.io/" },
  { logo: support_5, url: "https://nftgamingstars.com/" },
  { logo: support_6, url: "https://attarius.com/" },
  { logo: support_7, url: "https://simplio.io/" },
  { logo: support_8, url: "https://cronos.org/" },
  { logo: support_9, url: "https://coinstages.com/" },
  { logo: support_10, url: "https://test.zerosum.world/" },
  { logo: support_11, url: "https://jr-studio.io/" },
  { logo: support_12, url: "https://www.mantle.xyz/ru" },
  { logo: support_13, url: "https://reneverse.io/" },
  { logo: support_14, url: "https://beta.dequest.io/" },
  { logo: support_15, url: "https://lync.world/" },
  { logo: support_16, url: "https://xwg.games/" },
  { logo: support_17, url: "https://pomp.money/" },
  { logo: support_18, url: "https://metapromarket.com/" },
  { logo: support_19, url: "https://m.bitmetis.io/" },
  { logo: support_20, url: "https://app.zypher.game/" },
  { logo: support_21, url: "https://sakaba.xyz/" },
  { logo: support_22, url: "https://www.void.games/" },
  { logo: support_23, url: "https://magicsquare.io/" },
  { logo: support_24, url: "https://skale.space/" },
  { logo: support_25, url: "https://manta.network/" },
  { logo: support_26, url: "https://web3yak.com/app/" },
  { logo: support_27, url: "https://oasisprotocol.org/" },
  { logo: support_28, url: "" },
  { logo: support_29, url: "https://www.plena.finance/" },
  { logo: support_30, url: "https://thefishverse.com/" },
  { logo: support_31, url: "https://cwallet.com/" },
  { logo: support_32, url: "https://magiccraft.io/" },
  { logo: support_33, url: "https://www.forcefi.io/" },
  { logo: support_34, url: "https://powerdao.ai/" },
  { logo: support_35, url: "https://gametrade.market/" },
  { logo: support_36, url: "https://cryptomayhem.io/en/" },
  { logo: support_37, url: "" },
  { logo: support_38, url: "" },
  { logo: support_39, url: "https://www.trikon.io/" },
  { logo: support_40, url: "" },
  { logo: support_41, url: "https://www.gamerse.com/" },
  { logo: support_43, url: "https://nuls.io/" },
  { logo: support_44, url: "https://nabox.io/" },
  { logo: support_45, url: "https://www.shuttlebay.xyz/" },
  { logo: support_46, url: "https://micro3.io/" },
  { logo: support_47, url: "https://myria.com/" },
  { logo: support_48, url: "https://alienswap.xyz/" },
  { logo: support_50, url: "https://dashboard.transak.com/login" },
  { logo: support_1_add, url: "https://portalcoin.xyz/" },
  { logo: support_2_add, url: "https://li.fi/" },
  { logo: support_3_add, url: "https://pitch.com/public/3ad9698e-5e3c-4d93-a53a-bacf4b0344f6" },
  { logo: support_4_add, url: "https://gameswift.io/" },
  { logo: support_5_add, url: "https://degame.gitbook.io/degames-documentation/" },
  { logo: support_6_add, url: "https://macawclub.medium.com/" },
  { logo: support_7_add, url: "https://ezswap.io/#/index" },
  { logo: support_9_add, url: "https://twitter.com/FusionXnft/" },
  { logo: support_10_add, url: "https://phoenixblockchain.org/" },
];

const OurSupport = () => {
  return (
    <div className={classes.wrapper}>
      <img src={topImg} alt=""/>
      <img src={topImgMobile} className={classes.mobileImg} alt=""/>
      <div className={classes.contentWrapper}>
        <h2>Our Support, Investors and Partners</h2>
        <div className={classes.supportList}>
          {supportImages.map(i => (
            i.url ? (
                <a href={i.url} target={"_blank"}>
                  <img src={i.logo} alt=""/>
                </a>
              ) :
              <img src={i.logo} alt=""/>
          ))}
        </div>
      </div>
      <img src={bottomImg} alt=""/>
      <img src={bottomImgMobile} className={classes.mobileImg} alt=""/>
    </div>
  );
};

export default OurSupport;
