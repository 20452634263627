import React from 'react';
import classes from "./styles/character-types.module.css";
import CartoonButton from "../../components/CartoonButton/CartoonButton";
import character_img_1 from "../../assets/image/character-type-1.png"
import character_img_1_mobile from "../../assets/image/character-type-1-mobile.png"
import character_img_2 from "../../assets/image/character-type-2.png"
import character_img_2_mobile from "../../assets/image/character-type-2-mobile.png"
import character_img_3 from "../../assets/image/character-type-3.png"
import character_img_3_mobile from "../../assets/image/character-type-3-mobile.png"
import green_gem from "../../assets/image/green-gem.png"
import blue_gem from "../../assets/image/blue-gem.png"
import clsx from "clsx";

const characterTypes = [
  {
    name: "Regular Units",
    price: "$0.01 - 0.3 / unit",
    link: "https://wiki.heroesbattlearena.online/gameplay/army",
    image: character_img_1,
    mobile_image: character_img_1_mobile,
    description: [
      {
        icon: green_gem,
        text: <span>The player follows his own strategy of combinations of three elements to increase the chances of winning.</span>
      },
      {
        icon: blue_gem,
        text: <span>Three types of units available: Pawn, Magician and Shooter to cover so many strategies as you want to use.</span>
      }
    ]
  },
  {
    name: "Magic Stones",
    price: "$ 0.5 - 1.5 /stone",
    link: "https://wiki.heroesbattlearena.online/stones/",
    image: character_img_2,
    mobile_image: character_img_2_mobile,
    rtl: true,
    description: [
      {
        icon: green_gem,
        text: <span><b>Use 2 stones in each game</b> (or 3 in the premium subscription) to strengthen your army or weaken the enemy</span>
      },
      {
        icon: blue_gem,
        text: <span><b>Using one stone in the game</b> takes away 1 unit of magic Magic recover 5 points per hour</span>
      }
    ]
  },
  {
    name: "Gods",
    price: "$ 25-100 /god",
    link: "https://wiki.heroesbattlearena.online/gods/",
    image: character_img_3,
    mobile_image: character_img_3_mobile,
    description: [
      {
        icon: green_gem,
        text: <span><b>Playing with gods is an opportunity to influence the abilities of your enemy's army</b>, as well as its individual units, or to add power to your army units. But remember that the number of gods in the game is limited, and each sold god can enhance your opponent.</span>
      }
    ]
  }
]

const CharacterTypes = () => {
  return (
    <div className={classes.wrapper}>
      <div className={classes.headline}>
        <h2>3 types of characters.<br/>Billions strategies!</h2>
        <p>Heroes Battle Arena is a realm of endless possibilities, with three distinct classes of heroes to choose from
          and billions of strategic possibilities. Whether you prefer to lead a team of rugged warriors, powerful magic
          stones, or even GODS! With endless combinations of skills, abilities, and equipment to master, no two battles
          are ever the same.</p>
      </div>

      {characterTypes.map(i => (
        <div className={clsx(classes.characterType, i.rtl && classes.rtlCharacter)}>
          <div className={classes.info}>
            <h3>{i.name}</h3>
            <div className={classes.price}>{i.price}</div>
            <img src={i.mobile_image} className={classes.mobileImage} alt=""/>
            <div className={classes.description}>
              {i.description.map(i => (
                <div className={classes.descriptionRow}>
                  <img src={i.icon} alt=""/>
                  {i.text}
                </div>
              ))}
            </div>
            <CartoonButton component={"a"} href={i.link}>
              READ MORE
            </CartoonButton>
          </div>
          <img src={i.image} alt=""/>
        </div>
      ))}
    </div>
  );
};

export default CharacterTypes;
