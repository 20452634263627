import React from 'react';
import classes from "./styles/character-types.module.css";
import earnMoreClasses from "./styles/earn-more.module.css";
import CartoonButton from "../../components/CartoonButton/CartoonButton";
import premium_subscription_icon from "../../assets/image/premium_subscription.svg"
import guilds_icon from "../../assets/image/guilds.svg"
import army_supply from "../../assets/image/army_supply.svg"
import green_gem from "../../assets/image/green-gem.png"
import blue_gem from "../../assets/image/blue-gem.png"

const characterTypes = [
  {
    name: "Premium Subscription",
    price: "$ 100 /YEAR",
    link: "https://wiki.heroesbattlearena.online/gameplay/premium-subscription",
    image: premium_subscription_icon,
    description: [
      {
        icon: blue_gem,
        text: <span>The ability to control the battle</span>
      },
      {
        icon: green_gem,
        text: <span>Use 3 stones in one battle (in the standard version 2)</span>
      },
      {
        icon: blue_gem,
        text: <span>3 times faster MAGIC recovery</span>
      },
      {
        icon: green_gem,
        text: <span>Gods power +10% always</span>
      }
    ]
  },
  {
    name: "Guilds",
    price: "$ 50 /FOREVER",
    link: "https://wiki.heroesbattlearena.online/gameplay/premium-subscription",
    image: guilds_icon,
    rtl: true,
    description: [
      {
        icon: blue_gem,
        text: <span>1% of reward bank in the battle from each member</span>
      },
      {
        icon: green_gem,
        text: <span>Monthly HBA token drop</span>
      },
      {
        icon: blue_gem,
        text: <span>Own channel in HBA Community</span>
      },
      {
        icon: green_gem,
        text: <span>Own channel on HBA Discord</span>
      }
    ]
  },
  {
    name: "Increase Army Supply",
    price: "$ 20 /NFT",
    link: "https://wiki.heroesbattlearena.online/gameplay/nft",
    image: army_supply,
    description: [
      {
        icon: green_gem,
        text: <span>Adding +100 available to use Army Units</span>
      }
    ]
  }
]

const CharacterTypes = () => {
  return (
    <div className={classes.wrapper}>
      <div className={classes.headline}>
        <h2>Be unique and earn more. <br/> Much more!</h2>
        <p>Stand out from the crowd and elevate your earnings by selecting a superpower. <br/><br/>
          <b>Win and earn like never before.</b></p>
      </div>

      <div className={earnMoreClasses.earnMoreWrapper}>
        {characterTypes.map(i => (
          <div className={earnMoreClasses.earnMoreItem}>
            <img src={i.image} alt=""/>
            <h3>{i.name}</h3>
            <div className={classes.price}>{i.price}</div>
            <div className={classes.description}>
              {i.description.map(i => (
                <div className={classes.descriptionRow}>
                  <img src={i.icon} alt=""/>
                  {i.text}
                </div>
              ))}
            </div>
            <CartoonButton component={"a"} href={i.link}>
              READ MORE
            </CartoonButton>
          </div>
        ))}
      </div>
    </div>
  );
};

export default CharacterTypes;
