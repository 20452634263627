import React, {useEffect, useState} from 'react';
import classes from "./styles/news.module.css";
import axios from "axios";

const News = ({posts}) => {
  console.log(posts)
  return posts.length > 0 && (
    <div className={classes.wrapper}>
      <h2>Our news from @Medium</h2>
      <div className={classes.newsWrapper}>
        {posts.map((i, idx) => (
          <div key={idx} className={classes.newsItem}>
            <img src={i.imageUrl} alt=""/>
            <span className={classes.title}>{i.title}</span>
            <span className={classes.description}>{i.description}</span>
            <a href={i.url} rel="noreferrer" target={"_blank"}>READ MORE</a>
          </div>
        ))}
      </div>
    </div>
  );
};

export default News;
