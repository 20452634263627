import React, { useState } from 'react';
import classes from "./styles.module.css";
import logo from "../../assets/image/logo.png"
import {
  BurgerMenuBottom,
  BurgerMenuTop,
  DiscordIcon,
  TelegramIcon,
  TwitterIcon
} from "../../assets/icons/landingIcons";
import CartoonButton from "../CartoonButton/CartoonButton";
import clsx from "clsx";
import ExternalLink from "../ExternalLink";
import CreateHeroLink from "../CreateHeroLink";
import helper from "../../constants/helper";

const Header = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  }

  return (
    <>
      <div className={clsx(classes.headerWrapper, isMenuOpen && classes.activeMenu)}>
        <div className={classes.mediaLinks}>
          <TelegramIcon/>
          <TwitterIcon/>
          <DiscordIcon/>
        </div>
        <div className={classes.navigation}>
          <CreateHeroLink/>
          <ExternalLink href="https://pitch.com/public/dc107f8e-2d49-4b55-a204-68bf8e0c2144">
            WHITEPAPER
          </ExternalLink>
          <img src={logo} alt=""/>
          <ExternalLink
            style={{ marginLeft: "auto" }}
            href="https://wiki.heroesbattlearena.online/"
          >
            WIKI
          </ExternalLink>
          <a href="/">HBA TOKEN</a>
        </div>
        <a href={helper.PLAY_URL} target={"_blank"}>
          <CartoonButton>PLAY FREE NOW</CartoonButton>
        </a>
        <div className={classes.mobileHeaderWrapper}>
          <img src={logo} alt=""/>

          <div onClick={toggleMenu} className={classes.burgerMenu}>
            <BurgerMenuTop className={classes.burgerMenuLine}/>
            <BurgerMenuBottom className={classes.burgerMenuLine}/>
          </div>
        </div>
      </div>
      <div className={clsx(classes.burgerMenuContent, isMenuOpen && classes.activeMenu)}>
        <a href={helper.PLAY_URL} target={"_blank"}>
          <CartoonButton>PLAY FREE NOW</CartoonButton>
        </a>

        <div className={classes.navigation}>
          <CreateHeroLink/>
          <ExternalLink href="https://pitch.com/public/dc107f8e-2d49-4b55-a204-68bf8e0c2144">
            WHITEPAPER
          </ExternalLink>
          <ExternalLink href="https://wiki.heroesbattlearena.online/">
            WIKI
          </ExternalLink>
          <a href="/">HBA TOKEN</a>
        </div>

        <div className={classes.social}>
          <TelegramIcon height={32} width={32}/>
          <TwitterIcon height={32} width={32}/>
          <DiscordIcon height={32} width={32}/>
        </div>
      </div>
    </>
  );
};

export default Header;
