import React from 'react';

const CreateHeroLink = () => {
  const onClick = (e) => {
    e.preventDefault()
    document.getElementById("create-hero").scrollIntoView({behavior: "smooth", block: "start"});
  }
  return (
    <a style={{cursor: "pointer"}} href={"/"} onClick={onClick}>
      CREATE HERO
    </a>
  );
};

export default CreateHeroLink;
