import React from 'react';
import classes from "./cartoon-button.module.css";
import buttonBg from "../../assets/image/button-bg.svg"
import bigButtonBg from "../../assets/image/big-button-bg.svg"
import clsx from "clsx";

const CartoonButton = ({children, size, component, className, style, ...props}) => {
  const Component = component || "button"
  const backgroundImage = size === "big" ? bigButtonBg : buttonBg
  return (
    <Component
      className={clsx(classes.cartoonButton, className)}
      {...(component === "a" && {target: "_blank", rel: "noopener noreferrer"})}
      {...props}
      style={{backgroundImage: `url(${backgroundImage})`, ...style}}
    >
      {children}
    </Component>
  );
};

export default CartoonButton;
