import React from 'react';
import classes from "./styles/find-arena.module.css";
import phoenix from "../../assets/image/find-arena/phoenix.png"
import oasis from "../../assets/image/find-arena/rose.png"
import NULS from "../../assets/image/find-arena/nuls.png"
import cronos from "../../assets/image/find-arena/cro.png"
import manta from "../../assets/image/find-arena/manta.png"
import skale from "../../assets/image/find-arena/skale.png"
import mantle from "../../assets/image/find-arena/mantle.png"
import core from "../../assets/image/find-arena/core.png"
import haqq from "../../assets/image/find-arena/haqq.png"
import moonbeam from "../../assets/image/find-arena/moon.png"
import roburna from "../../assets/image/find-arena/roburna.png"
import placeholderImg from "../../assets/image/find-arena-video-placeholder.png"

const blockchains = [
  { src: phoenix, link: "" },
  { src: oasis, link: "" },
  { src: NULS, link: "" },
  { src: cronos, link: ""},
  { src: manta, link: ""},
  { src: skale, link: ""},
  { src: mantle, link: "" },
  { src: core, link: "" },
  { src: haqq, link: "" },
  { src: moonbeam, link: "" },
  { src: roburna, link: "" },
];

const FindArena = () => {
  return (
    <div className={classes.wrapper}>
      <div className={classes.blockchains}>
        <h2>Find your Arena on your favorite blockchain</h2>
        <div className={classes.blockchainList}>
          {blockchains.map(i => {
            const href = ""
            // const href = i.link
            const Component = href ? "a" : "div"
            return (
              <Component {...(href && { href })}>
                <img src={i.src} alt="" {...(i.style && {style: i.style})}/>
              </Component>
            )
          })}
        </div>
      </div>
      <img src={placeholderImg} alt=""/>
    </div>
  );
};

export default FindArena;
