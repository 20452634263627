import React from 'react';
import classes from "./styles/newsletter.module.css";
import CartoonButton from "../../components/CartoonButton/CartoonButton";

const Newsletter = () => {
  return (
    <div className={classes.wrapper}>
      <h2>Subscribe to Newsletters</h2>
      <div className={classes.subscribe}>
        <input placeholder="Your email address" type="email"/>
        <CartoonButton>
          SUBSCRIBE
        </CartoonButton>
      </div>
    </div>
  );
};

export default Newsletter;
