import React from 'react';
import classes from "./styles.module.css";
import { DiscordIcon, TelegramIcon, TwitterIcon } from "../../assets/icons/landingIcons";
import logo from "../../assets/image/logo.png";
import CartoonButton from "../CartoonButton/CartoonButton";
import ExternalLink from "../ExternalLink";
import CreateHeroLink from "../CreateHeroLink";
import helper from "../../constants/helper";

const Footer = () => {
  // const addJSONToPublicFolder = (obj = {test: "value", another: "val"}) => {
  //   const fs = require('fs');
  //   const path = require('path');
  //   const filePath = path.join(__dirname, 'public', 'data.json');
  //   fs.writeFileSync(filePath, JSON.stringify(obj), 'utf-8');
  //
  //   return filePath;
  // }
  return (
    <div className={classes.wrapper}>
      <div className={classes.footerTop}>
        <div className={classes.mediaLinks}>
          <TelegramIcon/>
          <TwitterIcon/>
          <DiscordIcon/>
        </div>
        <div className={classes.navigation}>
          <CreateHeroLink/>
          <ExternalLink href="https://pitch.com/public/dc107f8e-2d49-4b55-a204-68bf8e0c2144">
            WHITEPAPER
          </ExternalLink>
          <img src={logo} alt=""/>
          <ExternalLink
            style={{ marginLeft: "auto" }}
            href="https://wiki.heroesbattlearena.online/"
          >
            WIKI
          </ExternalLink>
          <a href="/">HBA TOKEN</a>
        </div>
        <a href={helper.PLAY_URL} target={"_blank"}>
          <CartoonButton>PLAY FREE NOW</CartoonButton>
        </a>
      </div>
      <div className={classes.navigationLaptop}>
        <CreateHeroLink/>
        <ExternalLink href="https://pitch.com/public/dc107f8e-2d49-4b55-a204-68bf8e0c2144">
          WHITEPAPER
        </ExternalLink>
        <ExternalLink href="https://wiki.heroesbattlearena.online/">
          WIKI
        </ExternalLink>
        <a href="/">HBA TOKEN</a>
        {/*<button onClick={() => addJSONToPublicFolder()}>test</button>*/}
      </div>
      <div className={classes.mediaLinksMobile}>
        <TelegramIcon height={32} width={32}/>
        <TwitterIcon height={32} width={32}/>
        <DiscordIcon height={32} width={32}/>
      </div>
      <span className={classes.copyright}>
        © {new Date().getFullYear()} Heroes Battle Arena. <br/>
        All Rights Reserved. <br/>
        From  🇺🇦 with ❤️
      </span>
    </div>
  );
};

export default Footer;
