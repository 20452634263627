import React, { useEffect, useState } from 'react';
import LandingHero from "../blocks/Landing/LandingHero";
import FindArena from "../blocks/Landing/FindArena";
import CharacterTypes from "../blocks/Landing/CharacterTypes";
import Newsletter from "../blocks/Landing/Newsletter";
import EarnMore from "../blocks/Landing/EarnMore";
import OurSupport from "../blocks/Landing/OurSupport";
import News from "../blocks/Landing/News";
import CreateHero from "../blocks/Landing/CreateHero";
import axios from "axios";
import API from "../services/http";
// import { Unity, useUnityContext } from "react-unity-webgl";
const Landing = () => {
  const [metrics, setMetrics] = useState({  });
  const [posts, setPosts] = useState([])
  // const { unityProvider } = useUnityContext({
  //   loaderUrl: "/build/Build/build.loader.js",
  //   dataUrl: "/build/Build/build.data",
  //   frameworkUrl: "/build/Build/build.framework.js",
  //   codeUrl: "/build/Build/build.wasm",
  // });

  useEffect(() => {
      fetch(
        "/.netlify/functions/heroSectionData",
      )
        .then(res => res.json())
        .then(res => {
          if (res && res.dappradar) {
            setMetrics(res.dappradar.results.metrics || {})
          }

          if (res && res.medium) {
            setPosts(res.medium.data.data.posts.slice(0, 3) || [])
          }
        })
  }, []);

  return (
    <div className={"landing-wrapper"}>
      {/*<Unity unityProvider={unityProvider}  style={{ width: 600, height: 800 }}/>*/}
      <LandingHero metrics={metrics}/>
      <FindArena/>
      <CharacterTypes/>
      <CreateHero/>
      <EarnMore/>
      <OurSupport/>
      <News posts={posts}/>
      <Newsletter/>
    </div>
  );
};

export default Landing;
