import React from "react";
import { lazy, Suspense } from 'react';
import { Routes, Route } from 'react-router-dom';
import moment from 'moment';
import routes from './constants/routes';
import Default from './components/Default';
import Landing from "./pages/Landing";

const Error = lazy(() => import('./pages/Error'));
moment.defaultFormat = 'YYYY-MM-DDTh:mm:ss';

function App() {
  return (
    <Suspense fallback={<Default />}>
      <Routes>
        <Route index element={<Landing />} />
        <Route path={routes.home} element={<Landing />}/>
        <Route path="*" element={<Error />} />
      </Routes>
    </Suspense>
  );
}

export default App;
